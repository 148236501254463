@import "partials/_mixins";
@import "partials/_variables";
@import "partials/_variables";


.cta-banner {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    gap: 15px;
    background-color: $color-background-dark-accent;

    @include mobile-and-tablet-breakpoint { 
      flex-direction: column;
      padding: 20px 60px;
      gap: 8px;
    }
  }

  &-text-container {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: center;
    gap: 3px;
    flex-basis: 600px;

    &.extended-text {
      flex-basis: 800px;

      @include mobile-and-tablet-breakpoint { 
        flex-basis: unset;
      }
    }

    @include mobile-and-tablet-breakpoint { 
      flex-direction: column;
      text-align: center;
      justify-content: center;
      flex-basis: unset;
    }
  }

  &-cta-container {
    display: flex;
    gap: 15px;
    
    button {
      width: 238px;
    }
    @include mobile-and-tablet-breakpoint { 
      flex-direction: column;
      gap: 16px;
    }
  }

  &-secondary-cta {
    @include mobile-and-tablet-breakpoint { 
      @include font-sm;
      line-height: 25px;
      &:not([disabled]) {
        height: 25px;
        border: none;
        text-decoration: underline;
      }
    }
  }

}