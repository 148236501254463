@import "partials/variables",
        "partials/mixins";
        
.notify-me-cta {
  margin: 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    line-height: 130%;
  }
}

.pdp-content-container {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 2px;
}


.radio-picker-container {
  display: flex;
  flex-direction: column;
  gap: 9px;

  input[type="radio"] {
    + label {
      @include proxima-body-copy;
      height: 22px;
      display: flex;
      align-items: flex-start;
      gap: 7px;
      font-size: $font-size-default;
      letter-spacing: 0;
      margin: 0;
      position: relative;
      text-transform: none;

      &:before {
        margin: 0;
        background: none;
        border: 1px solid $color-bag-secondary-copy;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        box-sizing: border-box;
      }

      &:after {
        content: " ";
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        left: 3px;
        top: 5px;
      }
    }

    &:checked + label:after {
      background-color: $color-black;
    }
  }

}

.radio-button-content {
  margin: 0;
  div,
  label,
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    color: $color-accessible-gray;
  }

  input[type="radio"] + label:before {
    border-color: $color-accessible-gray;
  }
}
